const BASE_URL = "https://impactzoneapi.appdeft.in/api/admin";
// const BASE_URL = "http://192.168.1.30:1102/api/admin";

// const HOST = "https://impactzoneapi.appdeft.biz";
const HOST = "";


// const BASE_URL = "http://localhost:1102/api/admin";
// const HOST = "http://localhost:1102";

export { BASE_URL, HOST };
